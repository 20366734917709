@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'swiper/scss';
@import 'swiper/scss/navigation';

@font-face {
    font-family: 'DMSans-Bold';
    src: url('/public/assets/fonts/DMSans-Bold.ttf');
}
@font-face {
    font-family: 'DMSans-semiBold';
    src: url('/public/assets/fonts/DMSans-SemiBold.ttf');
}
@font-face {
    font-family: 'DMSans-Medium';
    src: url('/public/assets/fonts/DMSans-Medium.ttf');
}
@font-face {
    font-family: 'DMSans-Regular';
    src: url('/public/assets/fonts/DMSans-Regular.ttf');
}
@font-face {
    font-family: 'DMSans-Thin';
    src: url('/public/assets/fonts/DMSans-Thin.ttf');
}
@font-face {
    font-family: 'DMSans-Thin';
    src: url('/public/assets/fonts/DMSans-Thin.ttf');
}
@font-face {
    font-family: 'DMSans-Light';
    src: url('/public/assets/fonts/DMSans-Light.ttf');
}


@layer components {

   
    .popup-css {
      @apply fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded bg-transparent w-[96%] mx-auto md:w-2/3 lg:w-1/2 xl:w-2/5 2xl:w-1/3 z-[99992]
    }
    
    .popup-box {
        @apply   max-h-[90vh] bg-white p-3 rounded-lg
    }
    
    .bg-overly {
      @apply w-full h-full fixed backdrop-blur-sm inset-0 z-[1029] bg-[#0000004D]
    }
  
 
    .input-box {
      @apply rounded-lg w-full font-customSemiBold text-sm md:text-[15px]  border-[1.5px] border-[#dcdcdc] text-black px-4 py-3 focus:outline-none placeholder:text-[#7A7A7A] placeholder:text-xs md:placeholder:text-sm placeholder:font-customMedium placeholder:capitalize
    }
    
  
    .theme-button {
      @apply rounded-lg w-full border text-[15px] cursor-pointer border-[#FF6500] capitalize font-customSemiBold text-white bg-[#FF6500] px-6 py-2  duration-500 ease-in-out
    }
  
    .password-icon{
      @apply absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 cursor-pointer
    }
  
    .closeBtn {
      @apply absolute -top-2 z-10 md:top-[-15px] end-[-0.35rem] md:end-[-15px] shadow-md bg-white flex justify-center items-center rounded-full text-black h-6 w-6 md:w-8 md:h-8 cursor-pointer
    }
    .error-message {
      @apply text-red-500 text-xs pt-1 ps-1 font-customMedium
    }
    
  }



html {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    /* scrollbar-color: #dcdcdc #f5f5f5; */
    scrollbar-gutter: stable;
    overscroll-behavior: none;
}


.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "" !important;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "" !important;
}
.swiper-button-prev , .swiper-button-next{
  background: #fff;
  height: 40px !important;
  width: 40px !important;
  min-width: 40px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  box-shadow: 0 0px 5px 4px #0000000a;
  
}
.swiper-button-prev img, .swiper-button-next img{
  width: 40px !important;
  min-width: 40px !important;
  aspect-ratio: 1/1;
  position: relative;

}
.swiper-button-prev {
  inset-inline-end: auto !important;
  inset-inline-start: -69px  !important;
}
.swiper-button-next{
  inset-inline-end: -69px !important;
  inset-inline-start: auto  !important;
}


@media screen and (max-width: 1280px) {
  .swiper-button-prev {
    inset-inline-end: auto !important;
    inset-inline-start: -50px  !important;
  }
  .swiper-button-next{
    inset-inline-end: -50px !important;
    inset-inline-start: auto  !important;
  }
}

@media screen and (max-width: 1024px) {
  .swiper-button-prev {
    inset-inline-end: auto !important;
    inset-inline-start: -15px  !important;
    height: 30px !important;
    width: 30px !important;
    min-width: 30px; 
    aspect-ratio: 1/1;
    img{
      width: 30px !important;
      min-width: 30px !important;
    }
  }
  .swiper-button-next{
    inset-inline-end: -15px !important;
    inset-inline-start: auto  !important;
    height: 30px !important;
    width: 30px !important;
    min-width: 30px;
    aspect-ratio: 1/1;
    img{
      width: 30px !important;
      min-width: 30px !important;
    }
  }
  
}
.product-arrivals .swiper-button-prev,
.product-arrivals .swiper-button-next,
.product .swiper-button-prev,
.product .swiper-button-next{
  top: 40%;
  transform: translateY(-50%);
}

.main-swiper .swiper-button-prev{
  inset-inline-end: auto !important;
  inset-inline-start: -20px  !important;
} 

.main-swiper .swiper-button-next{
  inset-inline-end: -20px !important;
  inset-inline-start: auto  !important;
}
.gallery-swiper .swiper-button-next{
  inset-inline-end: 0px !important;
  inset-inline-start: auto  !important;
}
.gallery-swiper .swiper-button-prev{
  inset-inline-end: auto !important;
  inset-inline-start: 0px  !important;
}
.swiper-slide-thumb-active{
  border: 2px solid #000000 !important; 
  border-radius: 8px !important;
}

.thumb-swiper .swiper-button-prev{
  inset-inline-end: auto !important;
  inset-inline-start: -10px  !important;
}
.thumb-swiper .swiper-button-next{
  inset-inline-end: -10px !important;
  inset-inline-start: auto  !important;
  
}


.custom-select .css-13cymwt-control{
  min-height: 45.6px !important;
  border-radius: 0.5rem !important;
  -webkit-overscroll-behavior-y: contain;
  overscroll-behavior-y: contain;
}
.custom-select .css-t3ipsp-control{
  min-height: 45.6px !important;
  border-radius: 0.5rem !important;
  border: 1px solid #dcdcdc !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-overscroll-behavior-y: contain;
  overscroll-behavior-y: contain;
}
.custom-select  .css-1u9des2-indicatorSeparator{
  display: none;
}
.custom-select  .css-1dimb5e-singleValue{
  color: #000000;
  font-family: 'DMSans-semiBold';
  font-size: 15px;
  margin: 2px 8px;
}
.custom-select  .css-1jqq78o-placeholder{
  color: #7a7a7a;
  font-family: 'DMSans-Medium';
  font-size: 14px;
  margin: 2px 8px;
}
.custom-select .css-qr46ko {
  max-height: 175px;
  -webkit-overscroll-behavior-y: contain;
  overscroll-behavior-y: contain;
}
.custom-select .css-14h4o58-menu {
  z-index: 10;
}
.custom-select  .css-14h4o58-menu,
.custom-select .css-1nmdiq5-menu{
  width: 98%;
  left: 1%;
}

@media screen and (max-width: 767px){
  .custom-select  .css-1dimb5e-singleValue{
    font-size: 14px;
  }
  .custom-select  .css-1jqq78o-placeholder{
    font-size: 12px;
  }
}

.country-select .css-1u9des2-indicatorSeparator{
  display: none;
}
.country-select .css-13cymwt-control{
  min-height: 45px !important;
  border-radius: 8px !important;
  border: 0px solid #dcdcdc !important;
  min-width:65px;
}
.country-select .css-hlgwow{
  padding: 0px !important;
}
.country-select .css-1xc3v61-indicatorContainer,
.country-select  .css-15lsz6c-indicatorContainer,
.country-select .css-1xc3v61-indicatorContainer svg,
.country-select  .css-15lsz6c-indicatorContainer svg{
  padding: 0px !important;
  width: 13px !important;
}

.country-select .css-19bb58m,
.country-select .css-19bb58m:after{
  min-width: 45px !important;
  width: 45px !important;
}
.country-select .css-t3ipsp-control{
  min-height: 45px !important;
  border-radius: 8px !important;
  border: 0px solid #dcdcdc !important;
  box-shadow: none;
  min-width:65px;
}
.country-select .css-tr4s17-option{
  background-color: white !important;
  color: #000 !important;
}
.country-select .css-tr4s17-option:focus{
  background-color: #f9f9f9 !important;
}

.country-select:focus {
  outline: none;
}

.scroll-style{
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.active-route img{
  filter: invert(11%) sepia(42%) saturate(2893%) hue-rotate(194deg) brightness(50%) contrast(103%);
}

.thumbnailSwiper .swiper-slide-thumb-active {
  border: 1px solid #012853; 
  opacity: 1; 
  border-radius: 5px;
  transition: border-color 0.3s ease; 
}

.thumbnailSwiper .swiper-slide img {
  opacity: 0.6;
  cursor: pointer;
}

.thumbnailSwiper .swiper-slide-thumb-active img {
  opacity: 1; 
}
.swiper-pagination-bullet,
.swiper-pagination-bullet-active{
  background-color: #fff !important;
  opacity: 1 !important;
  border: 1px solid #fff !important;
  transition: 0.5s ease-in-out !important;
  margin: 0 4px !important;
  width: 8px !important;
  height: 8px !important;
  max-height: 8px;
  max-width: 8px;
  border-radius: 100% !important;
  overflow: hidden !important;
}
.swiper-pagination-bullet-active{
  width: 20px !important;
  max-width: 20px;
  border-radius: 5px !important;
}
.main-pagination{
  position: static !important;
}
.gallery-pagination .swiper-pagination-bullet,
.main-pagination .swiper-pagination-bullet{
  background-color: #fff !important;
  border: 1px solid #012853 !important;
  opacity: 1 !important;
  transition: 0.5s ease-in-out !important;
}
.gallery-pagination .swiper-pagination-bullet-active,
.main-pagination .swiper-pagination-bullet-active{
  background-color: #012853 !important;
}



/* for temp added this loader */
@keyframes rotate { 0% { transform: rotate(0deg);} 100% { transform: rotate(360deg);}}


.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 200px;
  margin-left: -100px;
  margin-top: -100px;
  overflow: hidden;
  transform-origin: 100px 100px;
  -webkit-mask-image: -webkit-linear-gradient(top, rgba(0,0,0,1), rgba(0,0,0,0));
  animation: rotate 1.2s infinite linear;
  .loader-line {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    box-shadow: inset 0 0 0 3px rgb(255, 255, 255);
  }
}

.address-form  h2{
  display: none;
}
.address-form  .scroll-style{
  box-shadow: none;
  max-height: fit-content;
  width: 100%;
  max-width: 100%;
  padding: 0px;
}

.address-form  .map-image img{
  height: 200px;
  object-fit: cover;
}
@media screen and (max-width: 767px){
  .address-form  .map-image img{
    height: 130px;
    object-fit: cover;
  }
}
.address-list-div span:last-child::after{
  content: '.' !important;
}
.disable-select .css-olqui2-singleValue{
  color: #000000 !important;
}

.animation-heart{
  animation: beat cubic-bezier(0.04, 0.4, 0.5, 0.95) 0.65s forwards 1;
}
@keyframes beat {
  
  30% {
    opacity:1;
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
input[type="password"]::-ms-reveal {
  display: none;
}

input[type="password"]::-ms-clear {
  display: none;
}

.no-scroll {
  overflow: hidden !important;
  touch-action: none; 
}

.second-last-item:nth-last-child(2){
  border: 0px solid #dcdcdc !important;
  padding-bottom: 0px !important;
}
.thumbnailSwiper .swiper-button-prev,
.thumbnailSwiper .swiper-button-next,
.thumb-swiper .swiper-button-prev,
.thumb-swiper .swiper-button-next{
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  aspect-ratio: 1/1;
  top: 50%;
  transform: translateY(50%);
  img{
    width: 20px !important;
    height: 20px !important;
  }
}
.swiper-button-lock ,
.lg\:\!block.swiper-button-lock {
  display: none !important;
}
@media screen and (max-width: 912px) {
  .sm-body-scroll {
    overflow: hidden !important;
    touch-action: none; 
  }
}
.listing-scroll-style{
  scrollbar-width: thin;
}

