
.hide-overflow {
  overflow: hidden !important;
  touch-action: none !important;
  -ms-touch-action: none !important;
}
.scrollbar-thin {
  scrollbar-width: thin;
}
.selling .swiper-pagination-bullet-active,
.product .swiper-pagination-bullet-active,
.product-arrivals .swiper-pagination-bullet-active,
.product-arrivals  .swiper-pagination-bullet-active{
  background-color: #012853 !important;
}
.selling .swiper-pagination,
.product .swiper-pagination,
.product-arrivals .swiper-pagination,
.product-arrivals  .swiper-pagination{
  position: static !important;
}
.selling .swiper-pagination-bullet,
.product .swiper-pagination-bullet,
.product-arrivals .swiper-pagination-bullet,
.product-arrivals .swiper-pagination-bullet {
  border: 1px solid #012853 !important;
}



/* Hidden state */
.before-animation {
  opacity: 0;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.before-animation.slide-in-right {
  transform: translateX(40px);
  opacity: 0.4;
}
.before-animation.slide-in-left {
  transform: translateX(-40px);
  opacity: 0.4;
}



.before-animation.slide-in-top{
  transform: translateY(-40px);
  opacity: 0.4;
}
.before-animation.slide-in-bottom{
  transform: translateY(40px);
  opacity: 0.4;
}

.before-animation.animation-zoom{
  transform: scale(0.7);
  opacity: 0.4;
}

.before-animation.animation-rotate{
  transform: scale(0.7) rotate(20deg);
  opacity: 0.4;
}


.before-animation.animation-blur{
 filter: blur(40px);
  opacity: 0.8;
}

.before-animation.animation-semiBlur{
  filter: blur(10px);
   opacity: 0.8;
 }
 .second-image-slider .before-animation.animation-rotate{
  transform: scale(0.7);
  opacity: 0.4;
 }

/* Different animation styles */

.slide-in-left {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
}

.slide-in-right {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
}
.slide-in-top {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
}
.slide-in-bottom {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
}
.animation-zoom{
  transform: scale(1);
  opacity: 1;
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
}
.animation-rotate{
  transform: scale(1) rotate(0deg);
  opacity: 1;
  transition: all 0.75s ease-in-out;
}
.animation-blur{
  filter: blur(0px);
   opacity: 1;
   transition: all 0.75s ease-in-out;
 }

 .animation-semiBlur{
  filter: blur(0px);
   opacity: 1;
   transition: all 0.75s ease-in-out;
 }
 .second-image-slider .animation-rotate{
  transform: scale(1);
  opacity: 1;
  transition: all 1s ease-in-out;
 }

 .before-animation.animation-zoom-text{
  transform: scale(0.3);
  opacity: 0.4;
}
.animation-zoom-text{
  transform: scale(1);
  opacity: 1;
  transition: all 0.75s ease-in-out;
}


/*  */

.staggered-text span {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
}

.fade-in-up {
  animation: fadeInUp 0.75s ease forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.category-tabs .staggered-item {
  opacity: 0;
  z-index: 6;
  animation: fadeInTransform 700ms ease-in-out forwards;
  animation-delay: calc(0.1s * var(--index)); 
}
@keyframes fadeInTransform {
  0% {
    opacity: 0;
    transform:  scale(0.75);
  }
  100% {
    opacity: 1;
    transform:  scale(1);
  }
}
.main-box .staggered-item {
  opacity: 0;
  z-index: 6;
  animation: fadeInTopTransform 700ms ease-in-out forwards;
  animation-delay: calc(0.1s * var(--index)); 
}
@keyframes fadeInTopTransform {
  0% {
    opacity: 0;
    transform:  translateY(-20px);
  }
  100% {
    opacity: 1;
    transform:  translateY(0px);
  }
}

.heart {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%);
  animation: moveOut 3s ease forwards;
}

@keyframes moveOut {
  from {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


